// import mediaQuery from 'css-mediaquery';
import { createMuiTheme } from '@material-ui/core/styles';
import { breakpoints } from './constants/breakpoints';

// const ssrMatchMedia = (query) => ({
//   matches: mediaQuery.match(query, {
//     width: window.innerWidth,
//   }),
// });

const theme = createMuiTheme({
  // props: {
  //   MuiUseMediaQuery: { ssrMatchMedia },
  // },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#06BB9B',
    },
    secondary: {
      main: '#19857b',
    },
    background: {
      default: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '140%',
        boxShadow: '0px 2px 8px rgba(51, 36, 3, 0.0836702)',
        borderRadius: '4px',
        textTransform: 'none',
        padding: '16px 32px',
        color: '#ffffff',
        width: 'max-content',
        minWidth: 'max-content',
      },

      containedPrimary: {
        backgroundColor: '#06BB9B',
        color: '#ffffff',

        '&:hover': {
          backgroundColor: '#07A186',
        },
        '&:active': {
          backgroundColor: '#049077',
        },
        '&:disabled': {
          opacity: '0.5',
          backgroundColor: '#06BB9B',
          color: '#FFFFFF',
        },
      },

      containedSecondary: {
        backgroundColor: '#066BD6',

        '&:hover': {
          backgroundColor: '#0258B3',
        },
        '&:active': {
          backgroundColor: '#004694',
        },
        '&:disabled': {
          opacity: '0.5',
          backgroundColor: '#066BD6',
          color: '#FFFFFF',
        },
      },

      outlined: {
        padding: '14px 80px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '150%',
        border: '2px solid #222222',
        color: '#222222',
        boxShadow: 'none',
        background: 'transparent',

        '&:hover': {
          border: '2px solid transparent',
          color: '#066BD6',
          background: 'transparent',
        },
        '&:active': {
          border: '2px solid transparent',
          color: '#066BD60',
          background: 'transparent',
        },
        '&:disabled': {
          opacity: '0.5',
          background: 'transparent',
        },
      },
    },
    MuiSelect: {
      icon: {
        '@media (min-width: 1024px)': {
          right: '0',
        },
      },
    },
    MuiDialog: {
      paper: {
        [`@media ${breakpoints.mobile}`]: {
          position: 'relative',
          padding: '40px 15px 60px',
        },

        [`@media ${breakpoints.laptop}`]: {
          padding: '20px',
          width: '652px',
        },
      },
    },
    MuiInput: {
      multiline: {
        padding: 0,
      },
      input: {
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#5D5D5D',
        borderRadius: '4px',
        border: '1px solid #8F9BB3',
        padding: '12px 16px',
        '&:hover': {
          border: '1px solid #066BD6',
        },
        '&$disabled:hover': {
          border: '1px solid #8F9BB3',
        },
        '&:focus': {
          border: '1px solid #066BD6',
        },
      },
      underline: {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
        position: 'absolute',
        left: '8px',
      },
    },
    MuiTimelineDot: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '45px',
        width: '45px',
        borderRadius: '50%',
        background: '#06BB9B',
        border: '2px solid rgba(255, 255, 255, 0.7)',
        marginBottom: 0,
        marginTop: 0,
        padding: 0,

        [`@media ${breakpoints.tablet}`]: {
          height: '55px',
          width: '55px',
        },

        [`@media ${breakpoints.desktop}`]: {
          height: '75px',
          width: '75px',
        },
      },

      defaultPrimary: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '32px',
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
    MuiTimeline: {
      root: {
        padding: 0,
        margin: 0,
        alignItems: 'flex-start',

        [`@media ${breakpoints.tablet}`]: {
          width: '60%',
          maxWidth: '600px',
        },

        [`@media ${breakpoints.laptop}`]: {
          width: '650px',
          maxWidth: 'none',
        },

        [`@media ${breakpoints.desktop}`]: {
          width: '845px',
        },
      },
    },
    MuiTimelineItem: {
      root: {
        marginBottom: 0,
      },

      missingOppositeContent: {
        '&:before': {
          flex: 0,
          padding: '0 8px',
        },
      },
    },
    MuiTimelineContent: {
      root: {
        padding: '10px 16px 24px',

        [`@media ${breakpoints.tablet}`]: {
          padding: '15px 20px 24px',
        },

        [`@media ${breakpoints.desktop}`]: {
          padding: '20px 0 45px 40px',
        },
      },
    },
    MuiTimelineConnector: {
      root: {
        width: 0,
        border: '2px dashed rgba(255, 255, 255, 0.7)',
        backgroundColor: 'transparent',
      },
    },
    MuiTreeView: {
      root: {
        width: '100%',

        [`@media ${breakpoints.tablet}`]: {
          width: '75%',
          maxWidth: '670px',
        },

        [`@media ${breakpoints.laptop}`]: {
          width: '100%',
          maxWidth: 'none',
        },
      },
    },
    MuiTreeItem: {
      root: {
        marginBottom: '24px',

        [`@media ${breakpoints.laptop}`]: {
          marginBottom: '40px',
        },

        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'transparent',
        },
      },

      iconContainer: {
        [`@media ${breakpoints.mobile}`]: {
          width: '27px',
          marginRight: '16px',
        },

        [`@media ${breakpoints.laptop}`]: {
          width: '27px',
          marginRight: '17px',
        },
      },

      content: {
        alignItems: 'flex-start',

        [`@media ${breakpoints.laptop}`]: {
          alignItems: 'center',
        },
      },

      label: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAvatar: {
      root: {
        width: '55px',
        height: '55px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiAccordion: {
      root: {
        padding: 0,
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        '&.Mui-expanded': {
          minHeight: '48px',
          margin: 0,
        },
      },
      content: {
        margin: '16px 0',
        '&.Mui-expanded': {
          margin: '16px 0',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          color: '#066BD6',
        },
      },
    },
    MuiPopover: {
      root: {
        borderRadius: '40px',
      },
    },
  },
});

export default theme;
