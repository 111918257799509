// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-base-landing-index-js": () => import("./../../../src/components/base-landing/index.js" /* webpackChunkName: "component---src-components-base-landing-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-car-rent-faq-js": () => import("./../../../src/pages/car-rent-faq.js" /* webpackChunkName: "component---src-pages-car-rent-faq-js" */),
  "component---src-pages-car-rental-krasnodar-js": () => import("./../../../src/pages/car-rental-krasnodar.js" /* webpackChunkName: "component---src-pages-car-rental-krasnodar-js" */),
  "component---src-pages-carculator-js": () => import("./../../../src/pages/carculator.js" /* webpackChunkName: "component---src-pages-carculator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meta-js": () => import("./../../../src/pages/meta.js" /* webpackChunkName: "component---src-pages-meta-js" */)
}

